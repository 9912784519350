<template>
  <section>
    <!-- 占位盒子 -->
    <section style="height:70px;"></section>
    <section class="nav_outer">
      <header class="container flex nav_box">
        <a href="/" class="logo flexRow">
          <img src="/static/img/home/logo.png" alt="为乐信息科技logo" />
        </a>
        <nav class="flex">
          <a v-for="item in navList" :class="['navItem', activeId === item.key ? 'actItem' : '']" :key="item.key" @mouseover="aMouseover"
            :href="item.key==='product'?'javascript:void(0);':'/'+(item.key==='home'?'':item.redirect ? item.key+item.redirect : item.key)">
            {{ item.name }}
            <section class="drop-down-product" v-if="item.key == 'product' && showDropDown">
              <section class="center-box">
                <section class="flex-1">
                  <a class="product-item" href="/product/standard">
                    <section class="icon"></section>
                    <section class="product-item-name">
                      高校实验室安全规范管理平台
                      <section class="icon-hot"></section>
                    </section>
                  </a>
                  <a class="product-item" href="/product/wise">
                    <section class="icon icon4"></section>
                    <section class="product-item-name">
                      智慧实验室管理平台
                      <section class="icon-hot"></section>
                    </section>
                  </a>
                  <a class="product-item" href="/product/lims">
                    <section class="icon icon5"></section>
                    实验室信息管理系统LIMS
                  </a>
                </section>
                <section class="flex-1">
                  <a class="product-item" href="/product/classify">
                    <section class="icon icon2"></section>
                    <section class="product-item-name">
                      实验室安全分级分类管理系统
                      <section class="icon-hot"></section>
                    </section>
                  </a>
                  <a class="product-item" href="http://sj.weileit.com/#/login" target="_blank">
                    <section class="icon icon6"></section>
                    试剂通-SAAS实验室版
                  </a>
                  <a class="product-item" href="http://jxs.weileit.com/#/login" target="_blank">
                    <section class="icon icon6"></section>
                    试剂通-SAAS经销商版
                  </a>
                </section>
                <section class="flex-2">
                  <a class="product-item mb-15" href="/product/synthetical">
                    <section class="icon"></section>
                    实验室综合管理平台
                    <i class="el-icon-arrow-right icon-arrow"></i>
                  </a>
                  <section class="second-menu">
                    <section class="mr-35">
                      <a class="product-second-item" href="/product/hazardous">
                        <section class="product-second-item-name">
                          实验室危化品管理系统
                          <section class="icon-hot icon-hot-r"></section>
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/monitor">
                        <section class="product-second-item-name">
                          实验室智能环境监测系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/reservation">
                        <section class="product-second-item-name">
                          实验室开放预约管理系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/device">
                        <section class="product-second-item-name">
                          资产设备管理系统
                        </section>
                      </a>
                    </section>
                    <section class="mr-35">
                      <a class="product-second-item" href="/product/instrument">
                        <section class="product-second-item-name">
                          仪器预约共享管理系统
                          <section class="icon-hot icon-hot-r"></section>
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/inspect">
                        <section class="product-second-item-name">
                          实验室安全检查管理系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/education">
                        <section class="product-second-item-name">
                          实验/实践教学管理系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/sample">
                        <section class="product-second-item-name">
                          生物样本库管理系统
                          <section class="icon-hot icon-hot-r"></section>
                        </section>
                      </a>
                    </section>
                    <section>
                      <a class="product-second-item" href="/product/approve">
                        <section class="product-second-item-name">
                          实验室安全教育考试准入系统
                          <section class="icon-hot icon-hot-r"></section>
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/reagent">
                        <section class="product-second-item-name">
                          试剂耗材管理系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/report">
                        <section class="product-second-item-name">
                          实验室数据上报管理系统
                        </section>
                      </a>
                      <a class="product-second-item" href="/product/project">
                        <section class="product-second-item-name">
                          实验室建设项目管理系统
                        </section>
                      </a>
                    </section>
                  </section>
                </section>
              </section>
            </section>
          </a>
          <p class="number">
          <section class="icon"></section>
          400-169-8169
          </p>
        </nav>
      </header>
    </section>
  </section>
</template>

<script>
export default {
  name: "layoutNav",
  components: {},
  data() {
    return {
      navList: [
        {
          key: "home",
          name: "首页",
        },
        {
          key: "product",
          name: "产品中心",
        },
        {
          key: "case",
          name: "客户案例",
          redirect: '/college',
        },
        {
          key: "cooperate",
          name: "合作共赢",
          redirect: '/dealer',
        },
        {
          key: "news",
          name: "新闻中心",
          redirect: '/company',
        },
        {
          key: "about",
          name: "关于我们",
          redirect: '/company',
        },
      ],
      activeId: "home",
      showDropDown: false,
    };
  },
  methods: {
    aMouseover() {
      this.showDropDown = true;
    },
  },
  watch: {
    $route: {
      handler(to) {
        let index = 0
        if (to.path !== '/') {
          index = this.navList.findIndex((item) => {
            return to.path.indexOf(item.key) != -1;
          });
        }
        this.activeId = this.navList[index].key;
      },
      immediate: true,
    }
  },
};
</script>

<style scoped lang="scss">
.icon-arrow {
  font-size: 14px;
  margin-left: 2px;
  font-weight: bold;
}
.icon-hot {
  width: 28px;
  height: 16px;
  background: url("/static/img/home/nav-sprite.png");
  background-position: -276px -10px;
  position: absolute;
  top: -10px;
  right: -16px;
}
.icon-hot-r {
  right: -18px;
  top: -16px;
}
.nav_outer {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 999;
}
.nav_box {
  justify-content: space-between;
}
.logo {
  width: 202px;
  height: 70px;
  cursor: pointer;
}
.navItem {
  color: #262626;
  width: 100px;
  height: 70px;
  margin-right: 26px;
  line-height: 70px;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: #308dff;
  }
}
.navItem:hover .drop-down-product {
  display: block;
}
.actItem {
  color: #308dff;
  position: relative;
}
.actItem::before {
  content: "";
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: calc(50% - 25px);
  width: 50px;
  height: 100%;
  border-bottom: 3px solid #308dff;
  transition: 0.2s all linear;
}
.number {
  height: 70px;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 16px;
  line-height: 16px;
  .icon {
    background: url("/static/img/home/nav-sprite.png");
    background-position: -252px -6px;
  }
}
.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.drop-down-product {
  position: fixed;
  top: 70px;
  left: 0;
  width: 100%;
  background: #fff;
  display: none;
  z-index: 10;
  border-top: 1px solid #f8f8f8;
  animation: grow 0.6s;
  overflow: hidden;
}
.center-box {
  width: 1260px;
  margin: 0 auto;
  display: flex;
  padding: 16px 0 11px 0;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2.4;
  padding-right: 14px;
}
.mr-35 {
  margin-right: 35px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.product-item {
  display: flex;
  line-height: 30px;
  margin: 20px 0;
  font-size: 16px;
  color: #030303;
  align-items: center;
  .icon {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    background: url("/static/img/home/nav-sprite.png");
    background-position: 0px 0px;
  }
  .icon2 {
    background-position: -36px 0px;
  }
  .icon3 {
    background-position: -72px 0px;
  }
  .icon4 {
    background-position: -108px 0px;
  }
  .icon5 {
    background-position: -144px 0px;
  }
  .icon6 {
    background-position: -180px 0px;
  }
  .product-item-name {
    position: relative;
    .hot-arrow {
      position: absolute;
      top: 3px;
      right: 14px;
      transform: translateX(-50%);
      border: solid transparent;
      border-width: 2px;
      border-top-color: #fd6361;
    }
    .hot-content {
      right: -20px;
      position: absolute;
      top: -13px;
      transform: translateX(-50%);
      background-color: #fd6361;
      color: #fff;
      padding: 0 6px;
      line-height: 16px;
      border-radius: 4px;
      font-size: 12px;
    }
  }
}
.product-item:hover {
  color: #308dff;
}
.second-menu {
  display: flex;
  color: #8e8e8e;
  font-size: 14px;
  line-height: 16px;
  margin-left: 44px;
}
.product-second-item {
  display: flex;
  margin-bottom: 22px;
  .product-second-item-name {
    font-size: 16px;
    color: #8e8e8e;
    position: relative;
    cursor: pointer;
    .hot-arrow {
      position: absolute;
      top: 0px;
      right: -10px;
      transform: translateX(-50%);
      border: solid transparent;
      border-width: 2px;
      border-top-color: #fd6361;
    }
    .hot-content {
      right: -43px;
      position: absolute;
      top: -16px;
      transform: translateX(-50%);
      background-color: #fd6361;
      color: #fff;
      padding: 0 6px;
      line-height: 16px;
      border-radius: 4px;
      font-size: 12px;
    }
  }
  .product-second-item-name:hover {
    color: #308dff;
  }
}
.product-second-item:hover {
  color: #308dff;
}
@keyframes grow {
  from {
    height: 0;
  }
  to {
    height: 251px;
  }
}
</style>
